<template>
  <div class="pa-0 ma-0">
      <div v-show="!gauth" class="v-fade" ><loginform @callAlert="callAlert" @setGauth="setGauth" @setUsername="setUsername" @setPassword="setPassword"></loginform></div>
       <div v-show="gauth" class="v-fade" ><authform @callAlert="callAlert" :username="username" :password="password" :gauth="gauth"></authform></div>
      <alertVue
        :key="alertId"
        :alert.sync="alert"
        :alertText.sync="alertText"
        :alertType.sync="alertType"
      ></alertVue>
  </div>
</template>
<script>
import alertVue from "@/components/Alert";
import loginform from "@/components/login/loginForm";
import authform from "@/components/login/authForm";

export default {
  name: 'Login',
  components: {
    alertVue,
    loginform,
    authform,
  },
  data() {
    return {
      username: '',
      password: '',
      gauth:0,
      alertText:'',
      alertType:'',
      alert:'',
      alertId:'',
    };
  },
  created() {
    this.$i18n.locale = localStorage.getItem("language");
  },
  methods: {
      callAlert(item) {
        this.alertText = item.text;
        this.alertType = item.type;
        this.alert = item.alert;
        this.alertId = Date.now();
      },
      setGauth(val){
        this.gauth=val;
      },
      setUsername(val){
        this.username=val;
      },
      setPassword(val){
        this.password=val;
      },
  }
}
</script>

<style lang="scss" scoped>

.v-fade {
  display: inherit !important; /* override v-show display: none */
  animation-timing-function: ease-in-out;
  transition: opacity 1.5s;
}

.v-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}
</style>