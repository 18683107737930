<template>
    <v-card v-if="!gauth" class="d-block d-sm-flex justify-center align-center transparent" height="100vh" width="100vw">   
        <v-row class="pa-5 pt-12 ma-auto text-center pa-sm-auto">
            <v-col cols="12" sm="10" md="7" lg="5" class="ma-auto pa-0 my-border"> 
                <div class="ma-0 pa-0 customlightgray">
                    <div class="ma-0 pa-0 py-6">
                        <v-img
                            class="d-block pa-0"
                            height="100"
                            contain
                            :src="require('../../assets/axcess1_logo_v2.png')"
                        ></v-img>
                    </div>
                    <v-form  v-on:submit.prevent class="pa-2 pa-sm-12"
                            ref="form"
                            v-model="valid"
                            lazy-validation >
                            <v-text-field
                                v-model="username"
                                type='text'
                                outlined
                                :label="$t('username')"
                                :rules="[rules.required, rules.minU]"
                                counter
                                prepend-icon="mdi-account"
                                autofocus
                                autocomplete="off"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show ? 'text' : 'password'"
                                :label="$t('password')"
                                prepend-icon="mdi-lock"
                                counter
                                outlined
                                autocomplete="off"
                                @click:append="show = !show"
                                @keydown.enter="login"
                            ></v-text-field>
                            <v-btn class="pa-auto ma-auto custom-color-accent font-weight-medium" @click="login" depressed>{{$t("login")}}</v-btn>
                        </v-form>             
                </div>
                                               
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default({
    data() {
        return {
            valid: true,
            code:'',
            username:'',
            password:'',
            gauth:0,
            show:false,
            rules: {
                required: value => !!value || this.$t('required'),
                min: v => v.length >= 8 || this.$t('mincharacters'),
                minU: v => v.length >= 3 || this.$t('mincharacters3'),
            },
        }
    },
    methods: {
        login() {
           (this.$refs.form.validate())?
               this.$store.dispatch('retriveToken', {
                    username: this.username,
                    password: this.password,
                    }).then((r) => {
                      if(r.data.two_factor_auth){
                        this.gauth = 1;
                        this.$emit('setGauth',this.gauth);
                        this.$emit('setUsername',this.username);
                        this.$emit('setPassword',this.password);
                      }else{
                        this.$emit('setUsername',this.username);
                        this.$emit('setPassword',this.password);
                        this.$store.dispatch('retriveUserInfo').then(()=>{
                          let item = {text:this.$t("welcome")+'!', type:"success", alert:true};
                          this.$emit('callAlert',item);
                          this.$router.push({ name: 'dashboard' });
                        })
                      }
                    }).catch(() => {
                        let item = {text:this.$t("loginfailedalert"), type:"dark", alert:true};
                        this.$emit('callAlert',item);
                    })
            :'' 
        },     
    }
})

</script>
<style scoped>

</style>