<template>
    <v-card v-if="gauth" class="d-block d-sm-flex justify-center align-center transparent" height="100vh" width="100vw">   
        <v-row class="pa-5 pt-12 ma-auto text-center pa-sm-auto">
            <v-col cols="12" sm="10" md="7" lg="5" class="ma-auto pa-0 my-border"> 
                <div class="ma-0 pa-0 customlightgray">
                    <div class="ma-0 pa-0 py-6">
                        <v-img
                            class="d-block pa-0"
                            height="100"
                            contain
                            :src="require('../../assets/axcess1_logo_v2.png')"
                        ></v-img>
                    </div>
                    <v-form  v-on:submit.prevent class="pa-12"
                        ref="formcode"
                        v-model="valid"
                        lazy-validation >
                            <v-text-field
                                v-model="code"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required]"
                                :type="show ? 'text' : 'password'"
                                :label="$t('code')"
                                prepend-icon="mdi-shield-account"
                                counter
                                outlined
                                autocomplete="off"
                                @click:append="show = !show"
                                autofocus 
                                @keydown.enter="auth"
                            ></v-text-field>
                            <v-btn class="pa-auto ma-auto custom-color-accent font-weight-medium" @click="auth" depressed>{{$t("verify")}}</v-btn>
                        </v-form>             
                </div>
                                               
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default({
    props:['username','password','gauth'],
    data() {
        return {
            code:'',
            show:true,
            valid:true,
            rules: {
                required: value => !!value || this.$t('required'),
            },
        }
    },
    methods: {
        auth() {
            (this.$refs.formcode.validate())?
                this.$store.dispatch('retriveTokenauth', {
                    username: this.username,
                    password: this.password,
                    code:this.code,
                }).then(() => {
                    this.$store.dispatch('retriveUserInfo').then(()=>{
                        let item = {text:this.$t("welcome")+'!', type:"success", alert:true};
                        this.$emit('callAlert',item);
                        this.$router.push({ name: 'dashboard' });
                        })
                        }).catch(() => {
                        let item = {text:this.$t("logincodefailedalert"), type:"dark", alert:true};
                        this.$emit('callAlert',item);
                        })
            :'';
        },
    }
})

</script>
<style scoped>
</style>